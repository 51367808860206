@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-family: "Inter", sans-serif;

  --primary-brand-color: #7e57c2;
  --primary-brand-color-extra-light: rgba(126, 87, 194, 0.2);
  --primary-brand-color-light: rgba(126, 87, 194, 0.3);
  --primary-brand-color-medium: rgba(126, 87, 194, 0.7);
  --primary-brand-color-reduced: rgba(126, 87, 194, 0.9);

  --text-color: #2a2a2a;
}

body {
  margin: 0;
  font-family: var(--font-family);
  color: var(--text-color);
}

li,
button,
a {
  transition: 0.2s ease all;
}

a {
  cursor: pointer;
}

input,
textarea {
  outline: none;
  font-family: var(--font-family);
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}

input[type="checkbox"],
input[type="radio"] {
  accent-color: var(--primary-brand-color);
}

textarea {
  resize: none;
}

.image-gallery-svg {
  height: 40px !important;
  width: 40px !important;
}

.image-gallery-svg:hover {
  color: var(--primary-brand-color) !important;
}

.image-gallery-icon:hover {
  color: var(--primary-brand-color) !important;
}

.image-gallery-fullscreen-button > svg {
  height: 30px !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  border: 2px solid var(--primary-brand-color);
}

.image-gallery-thumbnail {
  border: 3px solid transparent;
}


.image-gallery-thumbnail:hover {
  border: 3px solid var(--primary-brand-color);
}